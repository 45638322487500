import React from 'react';
import ReactDOM from 'react-dom';
import './styles/global.css'
import { BrowserRouter } from 'react-router-dom';
import Auth0ProviderWithNavigate from './auth/auth0-provider-with-history';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
        <Auth0ProviderWithNavigate>
          <App />
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    </React.StrictMode>,
  document.getElementById('root')
);

