import Profile from "../components/Profile";

function ProfilePage() {

  return (
    <div className='h-screen w-screen flex flex-col justify-center'>
      <Profile/>
    </div>
  );
}

export default ProfilePage;
