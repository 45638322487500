import { Link } from "react-router-dom";
import AuthenticationButton from "./Buttons/AuthenticationButton";

const NavBar = () => {

    return (
      <div className="fixed top-0 w-screen h-10 flex justify-between items-center px-2 bg-gray-900 shadow">
        <div>
          <Link className="px-1" to="/">Home</Link>
          <Link className="px-1" to="/profile">Profile</Link>
        </div>
        <div>
          <AuthenticationButton />
        </div>

      </div>
    );
  }
  
  export default NavBar;