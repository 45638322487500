import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
      <div> 
            {isAuthenticated && 
                <div className='flex flex-col text-center'>
                    <img className="self-center" src={user!.picture} alt={user!.name} />
                    <h2>{user!.name}</h2>
                    <p>{user!.email}</p>
                </div>
            }
      </div>
    );
};
  

export default Profile;